import React from "react";

import { Icon } from '@types';

const RssIcon: Icon = ({ fill = "#08080B" }) => (
 <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
 
 <path stroke="null" id="svg_5" fill={fill} d="m7.571447,20.663615c0,1.733024 -1.401006,3.137624 -3.129587,3.137624c-1.728281,0 -3.129337,-1.4045 -3.129337,-3.137624c0,-1.733323 1.401056,-3.137973 3.129337,-3.137973c1.728581,0.00015 3.129587,1.4047 3.129587,3.137973l0,0z"/>
  <path stroke="null" id="svg_6" fill={fill} d="m1.312523,8.987028l0,4.233382c5.819322,0 10.553909,4.746002 10.553909,10.580828l4.220705,0c0,-8.167979 -6.628201,-14.81421 -14.774615,-14.81421l0,0z"/>
  <path stroke="null" id="svg_7" fill={fill} d="m1.312523,1.298889l0,4.286615c10.018808,0 18.168582,8.170078 18.168582,18.215735l4.273574,0c-0.000051,-12.408261 -10.066983,-22.50235 -22.442156,-22.50235l0,0z"/>
  </svg>
);

export default RssIcon;