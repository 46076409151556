import React from "react";

/**
 * Paste in your SVG logo and return it from this component.
 * Make sure you have a height set for your logo.
 * It is recommended to keep the height within 25-35px.
 * Logo comes with a property value called `fill`. `fill` is useful
 * when you want to change your logo depending on the theme you are on.
 */
export default function Logo({ fill }) {
  return (
    <svg viewBox="0 0 318.45 82.79" height="30px">
      <path
        fill={fill}
        d="M31.3,38.2L46,61.1H30.3l-8.7-14.3l-4.5,3.4v10.9H3.3V6.4h13.8v21.5c0,3.7-0.3,7.3-0.8,10.8h0.3c1.7-2.6,3.1-4.7,4.3-6.2
	l8.9-11.2h15.3L31.3,38.2z M82.6,25.3c3.3,3.1,4.9,7.6,4.9,13.5v6.1H62.9c0.1,2.2,0.9,4,2.4,5.2c1.5,1.3,3.6,1.9,6.3,1.9
	c2.4,0,4.6-0.2,6.7-0.7c2-0.5,4.2-1.2,6.6-2.3v9.9c-2.2,1.1-4.4,1.9-6.7,2.3c-2.3,0.4-5,0.6-8.2,0.6c-6.6,0-11.8-1.8-15.4-5.3
	C50.9,53,49,47.9,49,41.4c0-6.7,1.7-11.9,5.1-15.5c3.4-3.6,8.2-5.4,14.5-5.4C74.7,20.6,79.3,22.1,82.6,25.3z M75,36.2
	c0-1.9-0.6-3.5-1.6-4.6c-1-1.1-2.4-1.7-4.2-1.7c-1.6,0-3,0.5-4.1,1.5s-1.7,2.6-1.9,4.8H75z M120.2,20.6c-2.7,0-5.1,0.5-7.1,1.4
	c-2.1,0.9-3.7,2.3-4.9,4.2h-0.8l-1.7-4.9H95.2v39.8H109V43.7c0-4.5,0.5-7.7,1.5-9.5c1-1.9,2.7-2.8,5-2.8c1.7,0,2.9,0.7,3.7,2
	c0.8,1.3,1.1,3.3,1.1,6v21.7h13.7V35.2c0-4.6-1.2-8.2-3.7-10.8C128,21.8,124.6,20.6,120.2,20.6z M172.6,29.8v-8.5h-31.2v10.5h15.5
	l-16.5,21.5v7.8h33V50.6h-16.5L172.6,29.8z M217.9,41.1c0,6.5-1.8,11.6-5.3,15.2s-8.4,5.5-14.7,5.5c-6,0-10.9-1.9-14.4-5.6
	c-3.6-3.7-5.4-8.8-5.4-15.1c0-6.5,1.8-11.5,5.3-15.1c3.5-3.6,8.4-5.4,14.7-5.4c3.9,0,7.4,0.8,10.4,2.5c3,1.7,5.3,4,6.9,7.1
	C217.1,33.3,217.9,36.9,217.9,41.1z M204,41.1c0-3.4-0.4-6-1.3-7.7c-0.9-1.7-2.4-2.6-4.6-2.6c-2.1,0-3.6,0.9-4.5,2.6
	c-0.9,1.7-1.4,4.3-1.4,7.7c0,3.4,0.5,6,1.4,7.8c0.9,1.8,2.4,2.7,4.6,2.7c2.1,0,3.6-0.9,4.5-2.7C203.5,47.1,204,44.5,204,41.1z
	 M225.8,61.4c0,4-1.4,6-4.2,6c-1.3,0-2.7-0.2-4.2-0.6v10.7c0.9,0.2,2,0.4,3.5,0.6s2.8,0.3,3.9,0.3c4.7,0,8.3-1.2,10.9-3.7
	c2.6-2.5,3.8-5.9,3.8-10.3v-43h-13.7V61.4z M232.7,5.2c-2.6,0-4.4,0.5-5.6,1.5s-1.7,2.5-1.7,4.6c0,4,2.4,6,7.3,6c4.9,0,7.3-2,7.3-6
	c0-2-0.6-3.5-1.8-4.6C237.1,5.8,235.2,5.2,232.7,5.2z M273.1,20.6c-2.1,0-4.2,0.6-6.2,2c-2,1.3-3.6,3-4.8,5.1h-0.7l-2.1-6.4h-10.2
	v39.8h13.7V41.5c0-2.6,0.7-4.6,2-5.9c1.4-1.4,3.5-2.1,6.5-2.1c2,0,3.5,0.1,4.7,0.4l1.2-13l-0.8-0.1
	C275.5,20.7,274.3,20.6,273.1,20.6z M295.1,41.3c0-3.2,0.6-5.6,1.8-7.3c1.2-1.7,2.9-2.6,5.2-2.6c1.5,0,3,0.2,4.4,0.6
	c1.4,0.4,2.9,1,4.6,1.7l4-10.2c-4.1-2-8.4-3-13-3c-6.7,0-11.9,1.8-15.5,5.4c-3.6,3.6-5.4,8.7-5.4,15.4c0,6.6,1.7,11.6,5,15.1
	s8.3,5.3,14.8,5.3c5,0,9.2-1.2,12.8-3.5V47.3c-3.7,2.3-7.6,3.5-11.6,3.5C297.4,50.9,295.1,47.7,295.1,41.3z"
      />
    </svg>
  );
}
